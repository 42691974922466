import React from "react"
import { Field, reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux';
import SEO from "../components/seo"
import Question from "../components/question"
import helpers from "../scripts/helpers"
import database from "../../public/js/database"
import cssStyle from "../styles/modern-contact.css"
import Header from "../components/header"
import ReCAPTCHA from "react-google-recaptcha";
import { submitContact } from '../store/actions'
import { PropagateLoader } from 'react-spinners';

let recaptchaInstance;

if(typeof document !== "undefined"){
    var $ = require("jquery")
}

class ModernContact extends React.Component {

    render = () => {
        setTimeout(() => {
            if(typeof document !== "undefined")
                this.checkQuestionVisibility()
        }, 0);
        return (
            <>
                <SEO title="Contactez-nous" />
                <Header />
                <form id="someForm">
                    <div>
                        { this.askMeSteps() }
                    </div>
                    <ReCAPTCHA
                        ref={e => recaptchaInstance = e}
                        render="explicit"
                        sitekey="6LcLkaEUAAAAAJEdZJ2Wl_JScVQ6o4cH0N9jk-Fs"
                        size="invisible"
                        onChange={this.verifyCallback}
                    />
                </form>
            </>
        )
    }

    next = (value) => {
        this.setState({
            step: value
        })
    }

    askMeSteps = () => {
        switch (this.state.step) {
            case "welcome":
                return (
                    <div className="main-section">
                        <div className="text-center welcome">
                            <img src="/img/contact/hello.png" alt="contact-hello" />
                            <h1> Vous avez des questions? Des commentaires?  </h1>
                            <br /><br />
                            <div onClick={ e => this.next("questions") } className='pulse-button'>Bien sûr</div>
                        </div>
                    </div>
                )
            case "questions":
                return (
                    <div className="main-section">
                        <div className="text-center questions-form welcome">
                            <div
                                id={`question-0`}
                                className="question"
                            />
                            {
                                this.state.questions.map((question, index) =>
                                    (
                                        <div key={index} onClick={ e => this.validatePosition(index+1) }>
                                            <div key={index} id={`question-${index+1}`} className="question" onKeyDown={this.handleKeyPress}>
                                                <Field
                                                    question={question.question}
                                                    type={question.type}
                                                    options={question.options}
                                                    next={e => this.validate(question) }
                                                    component={Question}
                                                    name={question.name}
                                                />
                                                <div className="error-message"> {this.state.formErrors[question.name]} </div>
                                                <br />
                                                <a
                                                    href="#"
                                                    onClick={e => this.validate(question)}
                                                    className="btn blue"
                                                >
                                                    OK
                                                </a>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            <div
                                id={`question-${this.state.questions.length+1}`}
                                className="question"
                            />
                        </div>
                    </div>
                )

                case "finish":
                    return (
                        <div className="main-section">
                            {
                                this.state.submited ? (
                                    <React.Fragment>
                                        <h2 className="succeeded">
                                        {
                                            this.props.formCompleted ? (
                                                <div className="text-center">
                                                    <h2>
                                                        Merci et à bientôt... <br/>
                                                        <small>
                                                            Vous serez redirigé automatiquement vers la page d'accueil dans : {this.state.timer} secondes
                                                        </small>
                                                    </h2>
                                                </div>
                                            ) : (
                                                <div className="relative text-center">
                                                    <h2>
                                                        Merci de patienter...
                                                        <small>
                                                            Nous sommes entrain de traiter votre requête.
                                                        </small>
                                                        <div className="horizontal-center">
                                                            <PropagateLoader
                                                                sizeUnit={"px"}
                                                                size={15}
                                                                color={'#ff1c87'}
                                                            />
                                                        </div>
                                                    </h2>
                                                </div>
                                            )
                                        }
                                        </h2>
                                    </React.Fragment>
                                ):(
                                    <button onClick={this.submit} className="shinny"> Envoyer </button>
                                )
                            }
                        </div>
                    )

            default:
                break;
        }
    }

    constructor(props){
        super(props)

        let questions = database.contact.questions

        this.state = {
            step: "welcome",
            timer: 5,
            questions: questions,
            mainQuestion: 1,
            submited: false,
            formErrors: {},
            form: "contact"
        }

    }

    submit = () => {
        this.setState({
            submited: true
        })
    }

    handleKeyPress = (target) => {
        if(target.key=="Enter" ||  target.key=="Tab"){
        this.nextQuestion()
        }
    }

    nextQuestion = (index = null) => {

        let {mainQuestion, questions} = this.state

        if (mainQuestion != questions.length){
            this.setState({
                mainQuestion: index === null ? mainQuestion + 1 : index + 2
            })
        } else {
            this.setState({
                step: "finish",
                submited: true
            })
            this.executeCaptcha()
        }
        this.handleInputFocuse()
    }

    previousQuestion = () => {
        let {mainQuestion} = this.state
        if (mainQuestion > 0 )
            this.setState({
                mainQuestion: mainQuestion - 1
            })
            this.handleInputFocuse()
    }

    handleInputFocuse = () => {
        setTimeout(function() {
            $(".question.active input").focus()
        }, 100);
    }

    checkQuestionVisibility = () => {
        let {mainQuestion} = this.state
        let $allQuestions = $(".question")
        let $mainQuestion = $(`#question-${mainQuestion}`)
        let $previousQuestion = $(`#question-${mainQuestion-1}`)
        let $nextQuestion = $(`#question-${mainQuestion+1}`)
        $(document).ready(function(){
            $allQuestions.removeClass("active").hide()
            $mainQuestion.show().addClass("active")
            $previousQuestion.show()
            $nextQuestion.show()
        })
    }

    executeCaptcha = () => {
        recaptchaInstance.execute();
    }


    verifyCallback = (response) => {
        let dataPosted = { recaptcha: response }
        this.props.postForm({ additionalData: dataPosted, callBack: (exception) => {
            if (!exception) { this.initializeTimer() } else {
                this.setState({
                    step: "questions",
                    mainQuestion: 1,
                    submited: false
                })
            }
        }})
    }

    initializeTimer = () => {

        this.setState({ timer: 5 })

        setInterval(() => {
            let {timer} = this.state
            this.setState({ timer: timer - 1 })
        }, 1000);

        setTimeout(() => {
            let _ = typeof window !== "undefined" ? window.location.href = "/" : null
        }, 5000);
    }

    validatePosition = (questionIndex) => {
        let { mainQuestion, questions } = this.state
        if (questionIndex > mainQuestion) { return this.validate(questions[mainQuestion-1]) }
        else if(questionIndex < mainQuestion) { return this.previousQuestion() }
    }

    validate = (question) => {

        let {formErrors} = this.state
        let validForm = true
        let questionValue = this.props.formValues[question.name]

        if (question.validate.includes("require") && !helpers.validateRequire(questionValue)) {
            formErrors[question.name] = "--> Ce champ est obligatoire"
            validForm = false
        } else if (questionValue && question.validate.includes("email") && !helpers.validateEmail(questionValue)) {
            formErrors[question.name] = "--> Entrez une adresse e-mail valide"
            validForm = false
        } else if (questionValue && question.validate.includes("phone") && !helpers.validatePhoneNumber(questionValue)) {
            formErrors[question.name] = "--> Entrez une numéro de téléphone valide."
            validForm = false
        }

        if (validForm) {
            formErrors = {}
            this.nextQuestion()
        }

        this.setState({
            formErrors: formErrors
        })

    }

    componentDidMount = () => {
        let form = helpers.getParameterByName("from", this.props.location.href)
        this.setState({
            form: form
        })
        helpers.loadCSS(cssStyle, "#modern-contact")
    }

    componentWillUnmount = () => {
        helpers.unloadCSS("#modern-contact")
    }

}


const mapStateToProps = (state, props) => {
    const formId = helpers.getParameterByName("from", props.location.href)
    return {
        formSuccess: state.contactReducer.contactSuccess,
        formError: state.contactReducer.contactError,
        formCompleted: state.contactReducer.contactCompleted,
        formValues: getFormValues('ContactForm')(state),
        initialValues: {
            form_id: formId ? formId : 1
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        postForm: (formName) => dispatch(submitContact(formName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContactForm'
})(ModernContact))
