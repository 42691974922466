export default `
    @import url('https://fonts.googleapis.com/css?family=Karla');
    body {
        background-image: url("/img/contact/vector-background.png");
        background-repeat: no-repeat;
        background-position: bottom left;
        font-family: 'Karla', sans-serif;
        -webkit-transition: 2s; /* Safari */
        transition: 2s;
    }
    .text-center{
        text-align: center
    }
    .main-section {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .pulse-button {
        position: relative;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
        display: block;
        width: 125px;
        height: 125px;
        font-size: 1.3em;
        font-weight: light;
        font-family: 'Trebuchet MS', sans-serif;
        text-transform: uppercase;
        text-align: center;
        line-height: 125px;
        letter-spacing: -1px;
        color: white;
        border: none;
        border-radius: 50%;
        background: #3399FF;
        cursor: pointer;
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
        -webkit-animation: pulse 1.5s infinite;
    }

    .pulse-button:hover {
        -webkit-animation: none;
    }

    .pulse-button:active {
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    @-webkit-keyframes pulse {
        0% {
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        }
        70% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
        }
        100% {
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
        }
    }

    .question {
        min-height: 24vh;
        width: 100%;
        max-width: 840px;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: left;
        margin: 0px auto;
        opacity: 0.2;
        pointer-events: none;
    }

    .questions-form {
        width: 750px;
        overflow-y: hidden;
        height: 100vh;
    }

    @media only screen and (max-width: 700px) {
        .questions-form {
            overflow-y: auto;
        }
    }
    @media only screen and (max-width: 600px) {
        .questions-form {
            width: 100%;
        }
    }

    .question.active {
        margin: 6rem auto;
        pointer-events: auto;
        opacity: 1
    }

    .welcome{
        width: 100%
    }

    :focus{
        outline: none !important;
    }

    .input-container{
        position: relative;
    }

    input[type="text"] {
        font: 15px/24px "Lato", Arial, sans-serif;
        color: #333;
        width: 100%;
        box-sizing: border-box;
        letter-spacing: 1px;
    }

    .effect-3 {
        border: 0;
        padding: 7px 0;
        border-bottom: 1px solid #ccc;
    }


    .effect-3~.focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 99;
    }

    .effect-3~.focus-border:before,
    .effect-3~.focus-border:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #3399FF;
        transition: 0.4s;
    }

    .effect-3~.focus-border:after {
        left: auto;
        right: 0;
    }

    .effect-3:focus~.focus-border:before,
    .effect-3:focus~.focus-border:after {
        width: 50%;
        transition: 0.4s;
    }

    small {
        margin: 1rem 0;
        font-size: 0.6em;
        display: block;
        color: #656565;
    }

    .needs-options:hover {
        background-color: #3399FF;
        color: #fff;
    }

    .needs-options li {
        list-style: none;
    }

    .needs-options {
        display: block;
        cursor: pointer;
        padding: 0.4rem;
        border: 1px solid #b1b1b1;
        margin-bottom: 0.2rem;
    }

    .radio-button {
        display: none
    }

    .SVGInline-svg {
        margin: 0 1rem;
        opacity: 0
    }

    .radio-button:checked + .needs-options {
        background-color: #3399FF;
        color: #fff;
    }

    .radio-button:checked + .needs-options .SVGInline-svg {
        opacity: 1;
        fill: #fff;
    }

    @-webkit-keyframes sheen {
        0% {
          -webkit-transform: skewY(-45deg) translateX(0);
                  transform: skewY(-45deg) translateX(0);
        }
        100% {
          -webkit-transform: skewY(-45deg) translateX(12.5em);
                  transform: skewY(-45deg) translateX(12.5em);
        }
      }
      @keyframes sheen {
        0% {
          -webkit-transform: skewY(-45deg) translateX(0);
                  transform: skewY(-45deg) translateX(0);
        }
        100% {
          -webkit-transform: skewY(-45deg) translateX(12.5em);
                  transform: skewY(-45deg) translateX(12.5em);
        }
    }

    .shinny {
    cursor: pointer;
    background-color: #fff;
    padding: 0.75em 2em;
    text-align: center;
    text-decoration: none;
    color: #2194E0;
    border: 2px solid #2194E0;
    font-size: 24px;
    display: inline-block;
    border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
    }
    .shinny:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    -webkit-transform: skewX(-45deg) translateX(0);
            transform: skewX(-45deg) translateX(0);
    transition: none;
    }
    .shinny:hover {
    background-color: #2194E0;
    color: #fff;
    border-bottom: 4px solid #1977b5;
    }
    .shinny:hover:before {
    -webkit-transform: skewX(-45deg) translateX(13.5em);
            transform: skewX(-45deg) translateX(13.5em);
    transition: all 0.5s ease-in-out;
    }
    .text-area {
        display: block;
        width: 100%;
        font-family: inherit;
        background-color: transparent;
        font-size: 25px;
        line-height: 38px;
        overflow-wrap: break-word;
        resize: none;
        padding: 0px 0px 8px;
        border-radius: 0px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        transition: all 0.2s ease 0s;
        overflow: hidden;
    }
    .page-head__logo{
        float: none;
        width: 200px;
        font-size: 2.25rem;
        margin: 0;
        display: i;
    }
    .error-message{
        color: red
    }
`